body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Cormorant Garamond',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    overflow: hidden; /* Hide scrollbars */
    user-select: none;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'montserrat',
      monospace;
  }
  