.st0 {
  fill: rgba(7, 194, 7, 0.548);
  cursor: pointer;
  outline: none;
  transition: all 0.4s ease 0s;
  opacity: 0.5;
  stroke: white;
  justify-content: center;
  align-items: center;
  display: flex;
  stroke-width: 4px;
  width: 100%;
}
.b{
  opacity: 0;
  fill:none;
  transition: all 0.4s ease-in-out 0s;
}
.ba4-a4, .ba1-a1, .ba2-a2, .ba3-a3, .ba5-a5, .bb1-b1, .bb2-b2, .bc1-c1, .bc2-c2,  .bc3-c3, .bc4-c4, .bd1-d1, .bd2-d2, .bd3-d3{
  fill: #015e0d;
  opacity: 0.7;
  stroke: white;
  stroke-width: 4px;
  width: 100%;
}

.st0:hover {
  fill: #015e0d;
  opacity: 0.7;
     
 } 
 
 .st1{
    fill: transparent;
     stroke: white;
     stroke-width: 5px;
 }

 .st2{
    fill:rgba(0, 0, 0, 0.436);
     stroke: rgba(255, 255, 255, 0);

 }
 .st3{
  fill: #7c1010bd;
  stroke: white;
  stroke-width: 4px;
  opacity: 0.6;
 }
.cls-1 {
  fill: rgb(223, 200, 0);
  opacity: 0.999999;
}
.cls-a1-a1, .cls-a2-a2, .cls-a3-a3, .cls-a4-a4, .cls-a5-a5, .cls-b1-b1, .cls-b2-b2, .cls-c1-c1, .cls-c2-c2, .cls-c3-c3, .cls-c4-c4, .cls-d1-d1, .cls-d2-d2, .cls-d3-d3 {
  
  opacity: 0;
}

.wishListButton {
  color: white;
  background-color: white;
}